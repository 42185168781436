import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'emotion';

import ProfileNavigation from '../shared/ProfileNavigation';
import usePreference from '../shared/usePreference';
import Spinner from '../Spinner';
import EditButton from '../shared/EditButton';
import Api from '../Api';

const PreferenceContent = ({
  setEditing,
  preferences: { gender, language, counseled, reasons, other_reason },
}) => {
  const handleToggleEdit = () => {
    setEditing(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 className="card-title">Preferences</h5>
        <EditButton action={() => handleToggleEdit()} color={'green'} />
      </div>
      <dl>
        <dt>Do you have any gender preference for your counselor?</dt>
        <dd className="mb-3">{gender}</dd>
        <dt>Do you have any language preferences?</dt>
        <dd className="mb-3">{language}</dd>
        <dt>Have you tried counseling with a professional before?</dt>
        <dd className="mb-3">{counseled ? 'Yes' : 'No'}</dd>
        <dt>What brings you here?</dt>
        <ul className="mb-3">
          {reasons.map((r, index) => (
            <li key={index}>{r}</li>
          ))}
          {other_reason !== '' && <li>{other_reason}</li>}
        </ul>
      </dl>
    </>
  );
};

const NoPreference = () => (
  <>
    <h5 className="card-title">Preferences</h5>
    <p className="card-text">Help us match you to the right Counselor</p>
    <Link to="/responses/new">
      Please answer a few questions on your preferences
    </Link>
  </>
);

const Preferences = ({}) => {
  const [{ loading, preferences }] = usePreference();
  const [editing, setEditing] = useState(false);

  return (
    <div className="container personal-information pt-5">
      <div className="row">
        <ProfileNavigation />
        <div className="col-lg-9 col-md-8 col-sm-12 mt-2">
          <div className="card">
            <div
              className={cx(
                'card-body',
                css`
                  min-height: 100px;
                `,
              )}>
              {loading ? (
                <Spinner />
              ) : preferences && !editing ? (
                <PreferenceContent
                  preferences={preferences}
                  setEditing={setEditing}
                />
              ) : preferences && editing ? (
                <Form preferences={preferences} setEditing={setEditing} />
              ) : (
                <NoPreference />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Form = ({
  setEditing,
  preferences: { id, gender, language, counseled, reasons, other_reason },
}) => {
  const languages = useRef(
    (language || '').split(',').map((language) => language.trim()),
  );

  const [formData, setFormData] = useState({
    gender: gender,
    language: language,
    reasons: reasons,
    other_reason: other_reason,
    counseled: counseled,
  });

  const LanguageQuestionOptions = ({ name }) => {
    const [dummyState, setDummyState] = useState([]);
    const tempLanguages = new Set(languages.current);
    const preSelectedDialect = languages.current.filter(
      (language) => !['English', 'Filipino (Tagalog)'].includes(language),
    )[0];
    const [selectedDialect, setSelectedDialect] = useState(
      preSelectedDialect || '',
    );
    const [filipino, setFilipino] = useState(!!selectedDialect);
    const [filipinoLanguageOptions, setFilipinoLanguageOptions] = useState([]);
    const [dialectOptions, setDialectOptions] = useState([]);

    function handleLanguages(event, option, type) {
      // toggle filipino dialect checkbox and select
      const checked = event.target.checked;
      if (type === 'main') {
        if (option === 'Filipino (Dialect)') {
          if (checked) {
            setFilipino(true);
            tempLanguages.add(preSelectedDialect || dialectOptions[0]);
          } else if (checked === false) {
            // remove all dialects when unchecked
            dialectOptions.forEach((dialect) => tempLanguages.delete(dialect));
            setFilipino(false);
          }
        } else {
          if (checked) {
            tempLanguages.add(option);
          } else if (checked === false) {
            tempLanguages.delete(option);
          }
        }
        // adding dummy state to trigger re-render
        setDummyState([...dummyState]);
      }

      if (type === 'dialect') {
        dialectOptions.forEach((dialect) => tempLanguages.delete(dialect));
        tempLanguages.add(option);
        setSelectedDialect(option);
      }
      languages.current = Array.from(tempLanguages);
    }
    useEffect(() => {
      Api.get(`/api/v2/preferences/supported_values?type=languages`).then(
        (res) => {
          let languages = res.data.languages;
          let defaultOption = languages.filter((language) =>
            ['English', 'Filipino (Tagalog)'].includes(language),
          );
          let dialects = languages.filter(
            (language) => !['English', 'Filipino (Tagalog)'].includes(language),
          );
          setDialectOptions(dialects);
          setFilipinoLanguageOptions(defaultOption);
        },
      );
    }, []);

    return (
      <>
        {filipinoLanguageOptions.map((option, key) => {
          return (
            <div key={key}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={name}
                  value={option}
                  checked={languages.current.includes(option)}
                  id={option}
                  onChange={(event) => {
                    handleLanguages(event, option, 'main');
                  }}
                />
                <label className="form-check-label" htmlFor={option}>
                  {option}
                </label>
              </div>
            </div>
          );
        })}
        {dialectOptions.length > 0 && (
          <>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name={name}
                value={'Filipino (Dialect)'}
                id="Filipino (Dialect)"
                checked={filipino}
                onChange={(event) => {
                  handleLanguages(event, 'Filipino (Dialect)', 'main');
                }}
              />
              <label className="form-check-label" htmlFor="Filipino (Dialect)">
                Filipino (Dialect)
              </label>
            </div>
            {filipino && (
              <div className="form-group">
                <select
                  className="form-control"
                  name={name}
                  value={(selectedDialect || '').replaceAll(' ', '')}
                  onChange={(event) =>
                    handleLanguages(event, event.target.value, 'dialect')
                  }>
                  {dialectOptions.map((dielectOption, index) => (
                    <option key={index} value={dielectOption}>
                      {dielectOption}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const [formErrors, setFormErrors] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (checked) {
        setFormData((prevData) => ({
          ...prevData,
          reasons: [...prevData.reasons, value],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          reasons: prevData.reasons.filter((reason) => reason !== value),
        }));
      }
    } else if (type === 'radio') {
      setFormData({ ...formData, counseled: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.language = languages.current.join(', ');

    Api.patch(`/api/v1/preferences/${id}`, { preference: formData })
      .then((res) => {
        if (res.status == 200) {
          window.location.reload();
        }
      })
      .catch((_err) => {
        setFormErrors(_err.response.data.message);
        window.scroll(0, 0);
      });
  };

  const handleCancel = () => {
    // Handle cancel logic here, for example, resetting form data
    setFormData({
      gender: '',
      language: '',
      dialect: '',
      reasons: [],
      other_reason: '',
      counseled: '',
    });
    setEditing(false);
  };

  const formErrorsStyles = {
    color: 'red',
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 className="card-title">Preferences</h5>
      </div>
      {formErrors && <small style={formErrorsStyles}>{formErrors}</small>}
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="form-label font-weight-bolder">
              Do you have any gender preference for your counselor?
            </label>
            <br />
            <select
              className="form-control"
              name="gender"
              value={formData.gender}
              onChange={handleChange}>
              <option value="No preference">No preference</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
            </select>
          </div>
          <br />
          <div>
            <label className="form-label font-weight-bolder">
              Do you have any language preferences?
            </label>
            <LanguageQuestionOptions name={'language'} />
            <br />
          </div>
          <div>
            <label className="form-label font-weight-bolder">
              What brings you here? (Check all that apply)
            </label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am feeling anxious and unmotivated"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am feeling anxious and unmotivated',
              )}
            />
            <label>&nbsp;I am feeling anxious and unmotivated</label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am very stressed at work"
              onChange={handleChange}
              checked={formData.reasons.includes('I am very stressed at work')}
            />
            <label>&nbsp;I am very stressed at work</label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am feeling sad or depressed"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am feeling sad or depressed',
              )}
            />
            <label>&nbsp;I am feeling sad or depressed</label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am about to make a major life decision"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am about to make a major life decision',
              )}
            />
            <label>&nbsp;I am about to make a major life decision</label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am grieving the loss of a loved one"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am grieving the loss of a loved one',
              )}
            />
            <label>&nbsp;I am grieving the loss of a loved one</label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am experiencing emotional challenges in taking care of family members"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am experiencing emotional challenges in taking care of family members',
              )}
            />
            <label>
              &nbsp;I am experiencing emotional challenges in taking care of
              family members
            </label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am seeking counseling to better understand my child"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am seeking counseling to better understand my child',
              )}
            />
            <label>
              &nbsp;I am seeking counseling to better understand my child
            </label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am going through some marital and relationship concerns"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am going through some marital and relationship concerns',
              )}
            />
            <label>
              &nbsp;I am going through some marital and relationship concerns
            </label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am seeking couples counseling"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am seeking couples counseling',
              )}
            />
            <label>&nbsp;I am seeking couples counseling</label>
            <br />
            <input
              type="checkbox"
              name="reasons"
              value="I am having suicidal thoughts"
              onChange={handleChange}
              checked={formData.reasons.includes(
                'I am having suicidal thoughts',
              )}
            />
            <label>&nbsp;I am having suicidal thoughts</label>
            <br />
          </div>
          <div>
            <label>Others: &nbsp;</label>
            <input
              type="text"
              name="other_reason"
              value={formData.other_reason}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            <label className="form-label font-weight-bolder">
              Have you tried counseling with a professional before?
            </label>
            <br />
            <input
              type="radio"
              name="counseled"
              value={true}
              onChange={handleChange}
              checked={formData.counseled === true}
            />
            <label>Yes</label>
            &nbsp;&nbsp;
            <input
              type="radio"
              name="counseled"
              value={false}
              onChange={handleChange}
              checked={formData.counseled === false}
            />
            <label>No</label>
          </div>
          <br />
          <button
            className="mt-auto btn btn-general bg-white text-dark border rounded mr-2"
            type="button"
            onClick={handleCancel}>
            Cancel
          </button>
          <button className="btn-general rounded" type="submit">
            Save Preference
          </button>
        </form>
      </div>
    </>
  );
};

export default Preferences;
