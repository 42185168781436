import React from 'react';
import styled from '@emotion/styled';
import { Image } from 'react-bootstrap';

const StyledDiv = styled.div`
  color: ${({ theme }) => theme.colors.textBodyLight};
  border-radius: ${({ theme }) => theme.radius.default};
  border: ${({ theme }) => `1px solid ${theme.colors.textBodyLight}`};
  padding: 6px 8px;
  width: fit-content;
  display: flex;
  gap: 4px;
  span{
    line-height: 14px;
    padding-top: 3px;
    font-size: 0.75rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.textBodyLight};
    text-transform: uppercase;
  }
  &.dark-green{
    color: ${({ theme }) => theme.colors.textBodyLight};
    border-radius: ${({ theme }) => theme.radius.default};
    border: ${({ theme }) => `1px solid ${theme.colors.brandPrimaryDark}`};
    background-color: ${({ theme }) => theme.colors.brandPrimaryDark};
    span{
      color: ${({ theme }) => theme.colors.textWhite};
    }
  }
`;

const Chip = ({ icon, text, variant }) => {
  return (
    <StyledDiv className={`${variant}`}>
      <Image src={icon}/>
      <span>{text}</span>
    </StyledDiv>
  );
};

export default Chip;
