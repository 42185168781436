import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Row } from 'react-bootstrap';
import Layout from '../Layout';
import Sessions from '../Dashboard/Sessions';
import { StyledCol } from '../Dashboard/styles';
import { useParams } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import Track from '../shared/Track';
import axios from 'axios';

const StyledContainer = styled.div`
  .back {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: #33333366 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    border-radius: 30px;
    position: relative;

    img {
      width: 15px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: 30%;
      transform: translateY(-50%);
    }
  }

  .thumbnail {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .title {
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .video-metadata {
    text-align: left;

    img {
      margin-right: 8px;
    }
    .time {
      font-size: 12px;
      margin-right: 8px;
      letter-spacing: 0px;
      color: #191919;
      opacity: 1;
    }

    .playing {
      font-size: 12px;
      margin-right: 8px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #1db4b3;
      opacity: 1;
    }

    .description {
      font-size: 14px;
      letter-spacing: 0px;
      color: #191919;
      opacity: 0.7;
    }
  }
`;

const PodcastView = () => {
  const { id } = useParams();
  const [podcast, setPodcast] = useState({});

  useEffect(() => {
    userPodcast(id);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (podcast?.slug) {
      axios.post(`/api/v2/consumed_contents`, {
        slug: podcast.slug,
        content_type: 'podcast',
      });
    }
  }, [podcast]);

  const userPodcast = async (id) => {
    const res = await axios.patch(`/api/v2/podcasts/${id}/user_podcast`, {
      podcast: { play_status: 'playing' },
    });

    if (res.status === 200) {
      Track.podcastView({
        type: 'Podcasts',
        slug: res?.data.slug,
      });
      setPodcast(res.data);
    }
  };

  return (
    <Layout>
      <Row>
        <StyledCol lg={3}>
          <Sessions />
        </StyledCol>
        <StyledCol lg={9}>
          <StyledContainer>
            <div
              className="back"
              onClick={() => {
                window.location.href = '/explore?view=all';
              }}>
              <img src="/arrow-left-white.svg" alt="" />
            </div>
            <div className="thumbnail">
              <VideoPlayer podcast={podcast} />
            </div>
            <div className="title">{podcast?.title}</div>
            <div className="video-metadata">
              <img src="/podcast/podcast-icon.svg" alt="" />
              <label className="playing" htmlFor="">
                Now playing
              </label>
              <label className="time" htmlFor="">
                {podcast.estimated_time}
              </label>
            </div>

            <div className="description">{podcast.description}</div>
          </StyledContainer>
        </StyledCol>
      </Row>
    </Layout>
  );
};

export default PodcastView;
