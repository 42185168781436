import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import UIBack from '../../shared/ui/Back';
import styled from '@emotion/styled';
import * as moment from 'moment';
import CoachModal from './CoachModal';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import { useAppData } from '../../contexts/AppDataProvider';

const Header = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: 8px;
  text-align: center;
`;

const StyledSubHeader = styled.p`
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  opacity: 0.7;
  letter-spacing: 1.2px;
`;
const CoachContainer = styled.div`
  background-color: #f3fbfb;
  padding: 24px;
  height: 100%;
  .coach-info {
    .name {
      font-weight: 700;
      font-size: 14px;
    }
    .title {
      font-size: 12px;
    }
  }
`;

const ReminderContainer = styled.div`
  padding: 24px;
  background-color: #f8f8f8;
`;

export const StyledName = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const Avatar = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.radius.radiusRound};
`;
const ReminderLi = styled.li`
  margin-bottom: 17px;
  &::marker {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.textBodyLight};
  }
`;
const RebookOl = styled.ol`
  list-style: none;
  padding: 0;
  counter-reset: list-counter;
  margin-top: 10px;
  li{
    position: relative;
    padding-left: 32px;
    margin-bottom: 10px;
    counter-increment: list-counter;
    font-size: 12px;
    &::before{
      content: counter(list-counter);
      position: absolute;
      left: 0;
      top: 48%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-color: #EFEFEF;
      color: #333;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2px;
    }
  }
`;
const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.brandPrimaryDefault};
  font-weight: 700;
`;
const WhatsNextContainer = styled.div`
  margin: 24px;
`;
const BookingDone = () => {
  const [showCoachModal, setShowCoachModal] = useState(false);
  const {
    selectedSession,
    reviewDetails: { booking_details, professional },
    selectedMeetingSetup,
  } = useBookSessionData();

  const { cancellation_period_time_in_words } = useAppData();

  const getHeaderText = ({ selectedSession, selectedMeetingSetup }) => {
    const session = selectedSession === 'coaching' ? 'COACHING' : 'COUNSELING';
    const meetingSetup =
      selectedMeetingSetup === 'on_site' ? 'IN-PERSON' : 'VIRTUAL';
    return `${meetingSetup} ${session}`;
  };
  return (
    <>
      <CoachModal
        showCoachModal={showCoachModal}
        setShowCoachModal={setShowCoachModal}
        professional={professional}
      />
      <UIBack text={'Go Back Home'} to="/" />
      <Container>
        <Header>Get ready for your online {selectedSession} session!</Header>
        <Row>
          <Col lg={4} md={5}>
            <CoachContainer className="mb-4">
              <StyledSubHeader>
                {getHeaderText(selectedSession, selectedMeetingSetup)}
              </StyledSubHeader>
              <div className="d-flex mb-3 align-items-center">
                <Avatar
                  src={professional?.avatar_url || '/coaching/profile.png'}
                  className="mr-2"
                />
                <div className="coach-info">
                  <StyledName
                    className="name d-block"
                    onClick={() => setShowCoachModal(true)}>
                    {professional?.full_name}
                  </StyledName>
                  <span className="title d-block">{professional?.title}</span>
                </div>
              </div>
              <span className="d-block">
                {moment(booking_details?.meeting_date).format('dddd, LL')}
              </span>
              <span className="d-block">
                {moment(booking_details?.start_time)?.format('LT')} to{' '}
                {moment(booking_details?.end_time)?.format('LT')}
              </span>
            </CoachContainer>
          </Col>
          <Col lg={8} md={7}>
            <ReminderContainer>
              <StyledSubHeader>IMPORTANT REMINDERS</StyledSubHeader>
              <ul>
                {selectedMeetingSetup === 'virtual' && (
                  <ReminderLi>
                    Please join the <strong>Zoom</strong> link at the appointed
                    time.
                  </ReminderLi>
                )}
                <ReminderLi>
                  The {selectedSession === 'coaching' ? 'coach' : 'counselor'}{' '}
                  will wait up to 15 minutes, after which you will be marked as
                  no-show.
                </ReminderLi>
                <ReminderLi>
                  To rebook a session:
                  <RebookOl>
                    <li>
                      Cancel your existing booking more than{' '}
                      {cancellation_period_time_in_words} ahead of the original
                      appointment.
                    </li>
                    <li>Book a new appointment</li>
                  </RebookOl>
                </ReminderLi>
                <ReminderLi>
                  Cancelled sessions in less than{' '}
                  {cancellation_period_time_in_words}, and no-shows, will be
                  considered as <strong>‘completed’</strong> and will be
                  deducted from your benefit allowance.
                </ReminderLi>
              </ul>
            </ReminderContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <WhatsNextContainer>
              <StyledSubHeader className="mt-3 mb-4">
                WHAT'S NEXT?
              </StyledSubHeader>
              <p>
                You will receive an email from us with the following details:
              </p>
              <ul>
                <ReminderLi>
                  Confirmed date and time of your appointment
                </ReminderLi>
                <ReminderLi>
                  The link to the videoconferencing session (via Zoom)
                </ReminderLi>
                <ReminderLi>
                  Tips on how to prepare for your {selectedSession} session
                </ReminderLi>
              </ul>
              <p className="mt-4">
                If you have any questions or you need assistance, you can
                contact us at{' '}
                <StyledLink href="mailto:support@infinitcare.co">
                  support@infinitcare.co
                </StyledLink>
                .
              </p>
            </WhatsNextContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BookingDone;
