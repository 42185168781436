import React from 'react';
import styled from '@emotion/styled';
import { Col, Card } from 'react-bootstrap';
import { truncate } from '../../../../packs/helpers';
import { Link } from 'react-router-dom';
import Chip from '../../shared/ui/Chip';

const StyledContentCard = styled.div`
  display: flex;
  flex-direction: inherit;
  gap: 20px;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.surfaceDefault};
  border-radius: 8px;
  justify-content: space-between;
  &:hover {
    background-color: #e5f8f8;
  }
  @media (max-width: 767.98px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 576px) {
    padding: 1.25rem;
  }
`;

const StyledContentCardTitle = styled(Card.Title)`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 24px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledContentCardText = styled(Card.Text)`
  font-size: 0.875rem;
  line-height: 1.3rem;
  margin-bottom: 35px;
`;

const StyledInfoWrapper = styled.div`
  padding-top: 20px;
  padding-left: 8px;
  max-width: 58%;
  @media (max-width: 767.98px) {
    max-width: 100%;
  }
`;
const StyledImgWrapper = styled.div`
  max-width: 250px;
  @media (max-width: 767.98px) {
    max-width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 300px;
  }
  margin-top: auto;
  margin-bottom: auto;
`;
const StyledImg = styled.img`
  width: 100%;
`;
const StyledLink = styled(Link)`
  color: #333333;
  &:hover {
    text-decoration: none;
    color: #333333;
  }
`;

const ContentCardFullWidth = ({ withContent = true, search = false, data }) => {
  return (
    <Col lg={12} style={{ marginBottom: '2rem' }}>
      <StyledLink to={`/content/${data?.slug}`}>
        <StyledContentCard>
          <StyledInfoWrapper>
            <StyledContentCardTitle>{data?.title}</StyledContentCardTitle>
            <StyledContentCardText>
              {withContent && truncate(data?.description, 90)}
            </StyledContentCardText>
            <Chip
              icon={`/${data?.content_type}.svg?${new Date()}`}
              text={`${data?.estimated_time} ${
                parseInt(data?.estimated_time) > 1 ? ' MINS' : ' MIN'
              }`}
            />
          </StyledInfoWrapper>
          <StyledImgWrapper>
            <StyledImg
              src={data?.image_url ? data?.image_url : '/default_article.png'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/default_article.png';
              }}
            />
          </StyledImgWrapper>
        </StyledContentCard>
      </StyledLink>
    </Col>
  );
};

export default ContentCardFullWidth;
