import React from 'react';
import { Image } from 'react-bootstrap';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  padding: 0px;
  font-weight: 700;
  font-size: 16px;
  background: none;
  width: max-content;
  padding: 16px 24px;
  background: #1db4b3;
  border: 1px #1db4b3 solid !important;
  border-radius: 8px;
  color: white;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background-color: #1db4b3 !important;
    border-color: #1db4b3 !important;
    color: white !important;
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.6;
  }
`;

const NextButton = (props) => {
  return (
    <StyledButton type={props.type} className='green' {...props}>
      {props.text} <Image src="arrow-white-right.svg" />
    </StyledButton>
  );
};


export default NextButton;
