import React, { createContext, useContext, useState } from 'react';

const AppDataContext = createContext();

export function useAppData() {
  return useContext(AppDataContext);
}

const AppDataProvider = ({
  userId,
  firstName,
  lastName,
  email,
  verifiedEmail,
  verifiedPhone,
  companyID,
  companyName,
  companyPrefix,
  companyAdmin,
  companyCsManager,
  hasPreference: refHasPreference,
  consentSigned: refConsentSigned,
  consent,
  companyLogo,
  isCloaked,
  sessionsLeft,
  individualSessionsEnabled,
  phoneRequirementEnabled,
  salaryDeductionEnabled,
  withChat,
  withMoodTracker,
  assessment,
  access_to_assessment,
  emailValidity,
  cancellation_period_time_in_words,
  hasEmergencyContact,
  meetingSetup,
  phone,
  children
}) => {
  const bookASessionLandingPage = () => {
    if (refHasPreference && refConsentSigned && sessionsLeft > 0)
      return 'View Calendar';

    if (!refHasPreference) return 'Preference Page';
    if (!refConsentSigned) return 'Consent Form';
    if (!sessionsLeft || sessionsLeft <= 0) return 'Pricing Page';
  };

  const [currentAssessment, setCurrentAssessment] = useState(assessment);
  const [hasPreference, setHasPreference] = useState(refHasPreference);
  const [consentSigned, setConsentSigned] = useState(refConsentSigned);

  const value = {
    userId,
    firstName,
    lastName,
    email,
    verifiedEmail,
    verifiedPhone,
    hasPreference,
    setHasPreference,
    consentSigned,
    setConsentSigned,
    bookASessionLandingPage,
    companyLogo,
    isCloaked,
    companyID,
    companyName,
    companyPrefix,
    companyAdmin,
    companyCsManager,
    salaryDeductionEnabled,
    individualSessionsEnabled:
      individualSessionsEnabled == null ? true : individualSessionsEnabled,
    phoneRequirementEnabled: phoneRequirementEnabled == null ? true : phoneRequirementEnabled,
    withChat,
    withMoodTracker,
    currentAssessment,
    setCurrentAssessment,
    access_to_assessment,
    consentSigned,
    consent,
    sessionsLeft,
    emailValidity,
    cancellation_period_time_in_words,
    hasEmergencyContact,
    meetingSetup,
    phone
  };

  return (
    <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
  );
};

export default AppDataProvider;
