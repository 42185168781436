import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Selection } from './styles';
import Track from '../../shared/Track';
import styled from '@emotion/styled';

const StyledRow = styled(Row)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const SelectCounselingReasons = ({counselingReasons, selectedReasons, setSelectedReasons}) => {

  const toggleReason = (reason) => {
    if (selectedReasons.find((tempReason) => tempReason === reason)) {
      setSelectedReasons(
        selectedReasons.filter((tempReason) => tempReason !== reason),
      );
    } else {
      selectedReasons.push(reason);
      let newselectedReasons = [...new Set(selectedReasons)];
      setSelectedReasons(newselectedReasons);
    }
  };

  useEffect(() => {
    Track.pageView({
      page: 'Preference Page',
      purpose: 'coaching',
    });
  }, []);

  return (
    <>
      <StyledRow>
        {counselingReasons?.map((reason, index) => (
          <Col lg={6} md={12} key={index}>
            <Selection
              className={`${
                selectedReasons.find(
                  (tempReason) => tempReason === reason,
                )
                  ? 'active'
                  : ''
              } ${index % 2 == 0 ? 'even' : 'odd'}`}
              onClick={() => toggleReason(reason)}>
              {reason}
            </Selection>
          </Col>
        ))}
      </StyledRow>
    </>
  );
};
export default SelectCounselingReasons;
