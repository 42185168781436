import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import Layout from '../Layout';
import Sessions from '../Dashboard/Sessions';
import useDebounce from '../shared/hooks/useDebounce';
import { StyledCol } from '../Dashboard/styles';
import ExploreResults from './ExploreResults';
import ExploreCategories from './ExploreCategories';

export const StyledExplore = styled.h4`
  color: #1db4b3;
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
  @media (max-width: 767.98px) {
    margin-top: 20px;
  }
`;

export const HeaderContainer = styled.div`
  margin-bottom: 50px;
  & > div {
    display: flex;
    align-items: center;
  }
`;

const SearchWrapper = styled.div`
  padding: 5px;
  input {
    font-size: 13px;
  }
  input::placeholder,
  span.btn {
    color: #c1c1c1;
  }
  img {
    width: 100%;
    padding-left: 14px;
  }
  @media (max-width: 991.98px) {
    margin-top: 20px;
  }
`;

const SearchInput = styled.input`
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    background: url('/search_cancel.svg') no-repeat 50% 50%;
    background-size: contain;
  }
`;

const Explore = () => {
  const [keyWord, setKeyWord] = useState();
  const [contents, setContents] = useState([]);
  const [busy, setBusy] = useState(true);
  const contentsUrl =
    '/api/v2/contents/search?' +
    `terms[title]=${keyWord}&` +
    `terms[category]=${keyWord}`;

  const debouncedSearchTerm = useDebounce(keyWord, 500);

  async function fetchFeed() {
    await fetchContents();
    setBusy(false);
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchFeed();
    } else {
      setBusy(false);
    }
  }, [debouncedSearchTerm]);

  async function fetchContents() {
    const res = await fetch(contentsUrl);
    if (res.status === 200) {
      setContents(await res.json());
    }
  }

  const setKeyWordTerm = (event) => {
    setKeyWord(event.target.value);
    setBusy(true);
  };

  return (
    <Layout>
      <Row>
        <StyledCol lg={3}>
          <Sessions />
        </StyledCol>
        <StyledCol lg={9}>
          <HeaderContainer>
            <Row>
              <Col lg={4} md={12}>
                <StyledExplore>Explore</StyledExplore>
              </Col>
              <Col lg={8} md={12}>
                <SearchWrapper className="bg-white input-group border rounded-lg w-100">
                  <div className="input-group-prepend">
                    <img src="/search.svg" />
                  </div>
                  <SearchInput
                    style={{ lineHeight: '30px', height: '30px' }}
                    className="p-0 pl-2 form-control form-control-sm shadow-none border-0"
                    value={keyWord || ''}
                    type="search"
                    onChange={(event) => setKeyWordTerm(event)}
                    placeholder="Search our contents"
                    aria-label="search"
                    autoComplete="off"
                    name="keyword"
                  />
                </SearchWrapper>
              </Col>
            </Row>
          </HeaderContainer>
          {!!keyWord && (
            <ExploreResults contents={contents} keyWord={keyWord} busy={busy} />
          )}
          {!keyWord && <ExploreCategories />}
        </StyledCol>
      </Row>
    </Layout>
  );
};

export default Explore;
