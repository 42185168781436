import React, { useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { UIBackButton } from '../../shared/ui/Back';
import { StyledHeader } from './styles';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import { useAppData } from '../../contexts/AppDataProvider';
import Track from '../../shared/Track';
import { Pill } from '../../shared/ui';
import styled from '@emotion/styled';
import NextButton from '../../shared/ui/NextButton';
import SelectCounselingReasons from './SelectCounselingReasons';
import Api from '../../../Api';

const questions = [
  {
    name: 'gender',
    question: 'Do you have any gender preference for your counselor?',
    options: ['No preference', 'Female', 'Male'],
  },
  {
    name: 'language',
    question: 'Do you have any language preferences?',
    options: [],
  },
  {
    name: 'counseled',
    question: 'Have you tried counseling with a professional before?',
    options: ['true', 'false'],
  },
];

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
`;
const LanguagesContainer = styled.div`
  display: flex;
  gap: 24px;
`;
const StyledForm = styled.form`
  width: 80%;
  margin: 40px auto 40px auto;
  text-align: center;
`;
const Preferences = () => {
  const { setCurrentStep, previousStep } = useBookSessionData();
  const { setHasPreference } = useAppData();
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState(new Set());
  const [form, setForm] = useState({});
  const [showReason, setShowReason] = useState(false);
  const [counselingReasons, setCounselingReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!showReason) {
      setShowReason(true);
    } else {
      postPreference();
    }
  };

  useEffect(() => {
    Track.pageView({
      page: 'Preference Page',
      purpose: 'counseling',
    });
  }, []);

  const fetchLanguages = async () => {
    const res = await fetch(
      '/api/v2/preferences/supported_values?type=languages',
    );
    if (res.status === 200) {
      const data = await res.json();
      setLanguages(data.languages);
    }
  };

  const fetchCounselingReasons = async () => {
    const res = await fetch('/api/v2/counseling_reasons');
    if (res.status === 200) {
      const reasons = await res.json();
      setCounselingReasons(reasons);
    }
  };

  const postPreference = async () => {
    Api.post('/api/v1/preferences', {
      preference: {
        ...form,
      },
      reason: selectedReasons,
    }).then((res) => {
      if (res.status === 200) {
        setHasPreference(true);
        setCurrentStep('select_date_time');
      } else {
        window.location = res.data.return_url;
      }
    });
  };

  useEffect(() => {
    fetchLanguages();
    fetchCounselingReasons();
  }, []);

  function handleLanguages(option) {
    const included = Array.from(selectedLanguages).includes(option);

    if (!included) {
      selectedLanguages.add(option);
    } else {
      selectedLanguages.delete(option);
    }
    setSelectedLanguages(new Set(selectedLanguages));
    setForm({
      ...form,
      ...{ language: Array.from(selectedLanguages).join(', ') },
    });
  }

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep(previousStep)} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        {!showReason && <h3>Help us match you to the right counselor</h3>}
        {showReason && <h3>What is your reason? Select all that apply.</h3>}
      </StyledHeader>
      <StyledForm onSubmit={onSubmit}>
        {!showReason && (
          <>
            {questions.map((question, index) => (
              <div key={index} className="text-left mb-4">
                <StyledTitle>{question.question}</StyledTitle>
                <LanguagesContainer>
                  {question.name === 'language' && (
                    <>
                      {languages.map((language, index) => (
                        <Pill
                          key={index}
                          text={language}
                          className={`${
                            Array.from(selectedLanguages).includes(language)
                              ? 'active'
                              : ''
                          }`}
                          onClick={() => handleLanguages(language)}
                        />
                      ))}
                    </>
                  )}
                </LanguagesContainer>

                {question.name != 'language' && (
                  <>
                    {question.options.map((option, index) => (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={question.name}
                          value={option}
                          id={option}
                          onChange={() => {
                            setForm({
                              ...form,
                              ...{ [question.name]: option },
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor={option}>
                          {option}
                        </label>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
          </>
        )}
        {showReason && counselingReasons.length > 0 && (
          <SelectCounselingReasons
            counselingReasons={counselingReasons}
            selectedReasons={selectedReasons}
            setSelectedReasons={setSelectedReasons}
          />
        )}
        {!showReason && (
          <NextButton
            text="Select Reason"
            type="submit"
            disabled={!(Object.keys(form).length === 3)}
          />
        )}
        {showReason && (
          <NextButton
            text="Select Date & Time"
            type="submit"
            disabled={selectedReasons.length === 0}
          />
        )}
      </StyledForm>
    </>
  );
};
export default Preferences;
