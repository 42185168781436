import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { UICard, UIBack, UIButton } from '../../shared/ui';
import 'react-day-picker/dist/style.css';
import { StyledHeader, ImageContainer } from './styles';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import { useAppData } from '../../contexts/AppDataProvider';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const StyledUICard = styled(UICard)`
  background: ${({ theme }) => theme.colors.surfaceDefault};
  padding: 24px 24px 32px 24px;
  border-radius: ${({ theme }) => theme.radius.default};
  border: ${({ theme }) => theme.colors.borderDefault} 1px solid;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  &.active {
    color: #1db4b3;
    border: #1db4b3 2px solid;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SelectSession = ({ availableMeetingService }) => {
  const { selectedSession, setCurrentStep, setSelectedSession, resetFields } =
    useBookSessionData();
  const { companyID } = useAppData();
  const [sessionsLeft, setSessionsLeft] = useState(null);

  const fetchSessionLeft = async () => {
    // get updated sessions left from the api
    const res = await fetch('/api/v2/meetings');
    if (res.status == 200) {
      const { sessions_left } = await res.json();
      setSessionsLeft(sessions_left);
    }
  };

  useEffect(() => {
    fetchSessionLeft();
    resetFields();
  }, []);

  const setSession = (sessionType) => {
    let nextStep = 'select_reasons';
    if (companyID && sessionsLeft <= 0) {
      nextStep = 'out_of_session';
    } else if (sessionType == 'counseling') {
      nextStep = 'consent_form';
    }
    setSelectedSession(sessionType);
    setCurrentStep(nextStep);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      The coaching service is currently not available in your organization.
      Please contact HR for further assistance.
    </Tooltip>
  );

  const coachingServiceCard = (
    <StyledUICard
      className={`${
        availableMeetingService?.coaching_available ? '' : 'disabled'
      }`}>
      <ImageContainer className="text-center">
        <img src="/coaching.png" />
      </ImageContainer>
      <p className="mb-0 text-center">
        Speak with a life coach to help you identify and achieve your goals
      </p>
      <UIButton
        className="fit-content"
        onClick={() => {
          if (availableMeetingService?.coaching_available) {
            setSession('coaching');
          }
        }}
        text={'Coaching'}
      />
    </StyledUICard>
  );

  return (
    <>
      <UIBack to="/" />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>What is your session for?</h3>
        <p className="sub-title">
          Infinit Care offers both counseling and coaching sessions for its
          users.
        </p>
      </StyledHeader>
      <Row className="justify-content-center m-0">
        <Col lg={6}>
          <StyledUICard
            className={`mb-4 ${
              availableMeetingService?.counseling_available ? '' : 'disabled'
            }`}>
            <ImageContainer className="text-center">
              <img src="/counseling.png" />
            </ImageContainer>
            <p className="mb-0 text-center">
              Speak with a counselor to help you resolve the challenges to your
              well-being
            </p>
            <UIButton
              className="fit-content"
              onClick={() => {
                if (availableMeetingService?.counseling_available) {
                  setSession('counseling');
                }
              }}
              text={'Counseling'}
            />
          </StyledUICard>
        </Col>
        <Col lg={6}>
          {!availableMeetingService?.coaching_available && (
            <OverlayTrigger placement="top" overlay={tooltip}>
              <div>{coachingServiceCard}</div>
            </OverlayTrigger>
          )}
          {availableMeetingService?.coaching_available && coachingServiceCard}
        </Col>
      </Row>
    </>
  );
};
export default SelectSession;
