import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { StyledContainer, LeftContent, RightContent } from './cardstyles';
import styled from '@emotion/styled';
import ButtonWithArrow from '../../shared/ui/ButtonWithArrow';

const StyledDashboardCard = styled(StyledContainer)`
  background: #eefcce;
`;

const ViewPodcastsCard = () => {
  const history = useHistory();
  const showPodcasts = () => {
    history.push('/explore?view=podcast');
  };

  return (
    <StyledDashboardCard>
      <LeftContent>
        <h6>Listen, learn & transform your mental well-being</h6>
        <p>Check out our brand-new podcast section!</p>
      </LeftContent>
      <RightContent>
        <ButtonWithArrow
          variant="green"
          onClick={showPodcasts}
          text="View all Podcasts"
        />
      </RightContent>
    </StyledDashboardCard>
  );
};

export default ViewPodcastsCard;
