const theme = {
  radius: {
    default: '8px',
    radiusRound: '100000px',
  },
  colors: {
    textBodyLight: '#7E84A3',
    textWhite: '#FFFFFF',
    textBodyDefault: '#333333',
    surfaceDefault: '#F8F9FA',
    brandPrimaryDefault: '#1db4b3',
    brandPrimaryDark: '#164A4B',
    borderDefault: '#EFEFEF',
    borderEmphasis: '#C1C1C1',
    surfaceSubtleEmphasis: '#F3FBFB',
    surfaceContrast: '#EFEFEF',
  },
  spacing: {
    xs: '8px',
  },
};

export default theme;