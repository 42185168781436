import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

const StyledSubmitButton = styled(Button)`
  background: white;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 8px;
  border: 1px #1db4b3 solid;
  color: #1db4b3;
  border-radius: 8px;
  &:hover,
  &:focus {
    border: 1px #1db4b3 solid;
    background: white !important;
    color: #1db4b3 !important;
  }
`;

export default StyledSubmitButton;
