import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const StyledCol = styled(Col)`
  @media (min-width: 992px) {
    padding-left: 80px;
    padding-right: 0px;
  }

  @media (max-width: 575.98px) {
    padding-left: 15px;
  }
`;

export const SeeAllLink = styled(Link)`
  color: #1db4b3;
  font-weight: 600;
  opacity: 0.7;
  font-size: 0.75rem;
  &:hover {
    color: #1db4b3;
  }
`;
