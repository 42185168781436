import React from 'react';
import styled from '@emotion/styled';
import { Pill } from '../../../shared/ui';

const ArrangementContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 32px;
`;

const Header = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Arrangements = ({selectedArrangement, setSelectedArrangement}) => {
  return (
    <>
      <Header>Available Arrangements</Header>
      <ArrangementContainer>
        <Pill
          text="Any"
          className={`white ${
            selectedArrangement === 'hybrid' ? 'active' : ''
          }`}
          onClick={() => setSelectedArrangement('hybrid')}
        />
        <Pill
          image="friends.svg"
          text="In-Person"
          className={`white ${
            selectedArrangement === 'on_site' ? 'active' : ''
          }`}
          onClick={() => setSelectedArrangement('on_site')}
        />
        <Pill
          image="camera-video.svg"
          text="Virtual"
          className={`white ${
            selectedArrangement === 'virtual' ? 'active' : ''
          }`}
          onClick={() => setSelectedArrangement('virtual')}
        />
      </ArrangementContainer>
    </>
  );
};
export default Arrangements;
