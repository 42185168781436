import React from 'react';
import { Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import Layout from '../Layout';
import LearningModule from "./Card";
import Loader from "../Content/Card/Loader";
import {
  LEARNING_MODULES_PATH,
  MY_LEARNING_MODULES_PATH
} from "./DashboardSection";
import StyledSubmitButton from '../shared/StyledSubmitButton';

const LearningModules = () => {
  const learningModuleApiPath = window.location.pathname == "/my_learning_modules" ? MY_LEARNING_MODULES_PATH : LEARNING_MODULES_PATH;

  const ITEMS_PER_PAGE = 20;
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [learningModules, setLearningModules] = useState([]);
  const [outOfModules, setOutOfModules] = useState(true);

  useEffect(() => {
    getLearningModules();
  }, []);

  function getMoreLearningModules() {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    getLearningModules(nextPage);
  }

  async function getLearningModules(page) {
    if (! page) page = currentPage;

    const res = await fetch(learningModuleApiPath + `?page=${page}`);
    if (res.status === 200) {
      const list = await res.json();
      setLoading(false);

      if (list.length < ITEMS_PER_PAGE) {
        setOutOfModules(true);
      } else {
        setOutOfModules(false);
      }
      setLearningModules([...learningModules, ...list]);
    };
  }

  return (
    <Layout>
      { loading && <Loader /> }

      { !loading &&
        <h3 className="font-weight-bolder text-green">
          {learningModules.length > 0 ? "Learning Modules" : "There are no Learning Modules available yet."}
        </h3>
      }

      <Row>
        {learningModules.length > 0 && learningModules.map(module => (
          <LearningModule key={module.id} module={module} />
        ))}
      </Row>

      { !outOfModules &&
        <Row className="justify-content-center mt-4">
          <StyledSubmitButton onClick={() => getMoreLearningModules(currentPage)} className="mx-auto" style={{maxWidth: "fit-content"}}>
            See more
          </StyledSubmitButton>
        </Row>
      }
    </Layout>
  );
};

export default LearningModules;