import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  position: relative;
  padding: 24px;
  border-radius: 5px;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 767.98px) {
    flex-direction: column;
    gap: 20px;
  }
  h6 {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 4px;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
  }
`;

export const LeftContent = styled.div`
  @media (max-width: 767.98px) {
    width: 100%;
    text-align: left;
  }
`;
export const RightContent = styled.div`
  width: min-content;
  text-align: right;
  @media (max-width: 767.98px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
