import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import MoodTacker from '../../Individual/MoodTracker';
import Layout from '../Layout';
import { Row, Col } from 'react-bootstrap';
import Sessions from '../Dashboard/Sessions';
import HowItWorksModal from './HowItWorksModal';
import Api from '../../Api';
import CheckInModal from '../Dashboard/CheckInModal';
import CheckInDashboard from '../Dashboard/components/CheckInDashboard';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin: 0px;
    color: #1db4b3;
    text-decoration: underline;
    font-weight: bold;
    background-color: white;
    border: none;
    font-size: 14px;
  }
`;

export const StyledInsights = styled.h4`
  color: #1db4b3;
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
`;

export const StyledCol = styled(Col)`
  margin-bottom: 58px;
  @media (min-width: 992px) {
    padding-left: 33px;
    padding-right: 33px;
  }

  @media (max-width: 575.98px) {
    padding-left: 15px;
  }
`;

export default () => {
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const toggleShowHowItWorks = () => {
    setShowHowItWorks(!showHowItWorks);
  };
  const [showCheckInDashboard, setShowCheckInDashboard] = useState(false);
  const [showCheckInModal, setShowCheckInModal] = useState(false);

  useEffect(() => {
    Api.get('/api/v2/check_ins/status')
      .then((res) => {
        setShowCheckInDashboard(res.data.should_check_in);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const hideCheckInDashboard = () => {
    setShowCheckInDashboard(false);
  };

  return (
    <>
      <Layout>
        <Row>
          <StyledCol lg={3}>
            <Sessions />
          </StyledCol>
          <StyledCol lg={9} sm={12}>
            <StyledHeader className={showCheckInDashboard ? 'mb-4' : ''}>
              <StyledInsights> Insights </StyledInsights>
              <button onClick={() => toggleShowHowItWorks()}>
                How It Works
              </button>
            </StyledHeader>
            {showCheckInDashboard && (
              <CheckInDashboard setShowCheckInModal={setShowCheckInModal} />
            )}
            <MoodTacker />
          </StyledCol>
        </Row>
        {showCheckInModal && (
          <CheckInModal
            setShowCheckInModal={setShowCheckInModal}
            hideCheckInDashboard={hideCheckInDashboard}
          />
        )}
        {showHowItWorks && (
          <HowItWorksModal toggleShowHowItWorks={toggleShowHowItWorks} />
        )}
      </Layout>
    </>
  );
};
