import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useAppData } from '../../contexts/AppDataProvider';
import { LeftContent, RightContent, StyledContainer } from './cardstyles';
import ButtonWithArrow from '../../shared/ui/ButtonWithArrow';

const StyledDashboardCard = styled(StyledContainer)`
  background: #f3fbfb;
  flex-direction: column;
  gap: 20px;
`;

const StyledLeftContent = styled(LeftContent)`
  .emphasize {
    font-weight: 700;
  }
`;

const StyledRightContent = styled(RightContent)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const StyledButtonWithArrow = styled(ButtonWithArrow)`
  font-size: 12px;
`;

const AssessmentResultCard = () => {
  const history = useHistory();
  const { currentAssessment } = useAppData();
  if (!currentAssessment) {
    return <></>;
  }

  const isDone = currentAssessment?.done;

  const openAssessment = () => {
    let url = '';
    if (isDone) {
      url = '/assessment/result';
    } else if (currentAssessment?.status === 'in_progress') {
      url = '/assessment';
    } else {
      url = '/assessment/user_form';
    }
    history.push(url);
  };

  return (
    <StyledDashboardCard>
      {isDone && (
        <>
          <StyledLeftContent>
            <p>
              You scored a total of{' '}
              <strong className="emphasize">
                {currentAssessment.score} points
              </strong>{' '}
              in our mental health assessment.
            </p>
          </StyledLeftContent>
          <StyledRightContent>
            <ButtonWithArrow
              variant="green"
              onClick={openAssessment}
              text="View Result"
            />
          </StyledRightContent>
        </>
      )}
      {!isDone && (
        <>
          <p>Know your well-being score.</p>
          <StyledButtonWithArrow
            variant="black"
            onClick={openAssessment}
            text="Continue Assessment"
          />
        </>
      )}
    </StyledDashboardCard>
  );
};

export default AssessmentResultCard;
