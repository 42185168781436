import styled from '@emotion/styled';

const StyledHeading = styled.h2`
  font-size: 0.75rem;
  font-weight: bold;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  opacity: 0.7;
  margin-bottom: 30px;
`;

export default StyledHeading;