import React from 'react';
import styled from '@emotion/styled';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import UIProfileImage from '../../../shared/ui/ProfileImage';
import { StyledName } from '../BookingDone';

const StyledToolTip = styled(Tooltip)`
  .arrow::before {
    display: none;
  }
  .tooltip-inner {
    font-size: 14px;
    background-color: #C1C1C1;
    border-radius: ${({ theme }) => theme.radius.default};
    color: black;
    max-width: 300px;
    padding: ${({ theme }) => theme.spacing.xs};
    white-space: pre-line;
    text-align: left;
  }
  padding: 8px;
`;

const CounselorContainer = styled.div`
  margin: auto;
  margin-top: 20px;
  margin-top: 20px;
  max-width: 662px;
  display: flex;
  align-items: center;
  gap: 10px;
  .professional-image {
    width: 30px;
    height: 30px;
  }
  .name {
    font-weight: 700;
  }
`;

const ProfessionalInfo = ({ professional, onClick }) => {
  const renderTooltip = (props) => (
    <StyledToolTip id="button-tooltip" {...props}>
      <span>
        <strong>Why were you matched with this counselor?</strong>
      </span>
      <br />
      <span>
        We matched you with the most suitable counselor based on your needs and
        the counselors&apos; availability at your selected date and time.
      </span>
    </StyledToolTip>
  );

  return (
    <>
      <CounselorContainer>
        <UIProfileImage
          src={professional?.avatar_url || '/coaching/profile.png'}
          className="professional-image"
        />
        <StyledName className="name d-block" onClick={onClick}>
          {professional?.full_name}
        </StyledName>
        {professional?.title && (
          <span className="title d-block">{professional?.title}</span>
        )}
        <OverlayTrigger overlay={renderTooltip}>
          <Image src="info-circle.svg" />
        </OverlayTrigger>
      </CounselorContainer>
    </>
  );
};

export default ProfessionalInfo;
