import React from 'react';
import { Link } from 'react-router-dom';
import Track from '../../shared/Track';
import styled from '@emotion/styled';
import ButtonWithArrow from '../../shared/ui/ButtonWithArrow';
import Chip from '../../shared/ui/Chip';
import { formatDateTime } from '../../shared/utils/formatDateTime';

const StyledContentCard = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 30px;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.surfaceDefault};
  border-radius: 8px;
  @media (max-width: 767.98px) {
    flex-direction: column;
  }
`;

const StyledImageWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 20%;
  @media (max-width: 767.98px) {
    width: 100%;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
  }
`;

const StyledInfoContainer = styled.div`
  @media (max-width: 575.98px) {
    padding: 0px;
  }
`;

const Date = styled.time`
  margin-bottom: 4px;
  display: block;
  font-size: 0.875rem;
`;

const Title = styled(Link)`
  padding-top: 7px;
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
  color: #333333;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  max-width: 433px;
  &:hover {
    color: #333333;
  }
`;

const ButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const WebinarCardFullWidth = ({ webinar }) => {
  const meeting_date = formatDateTime(webinar.event_date, 'MMMM dd, EEEE');
  const openWebinar = () => {
    if (webinar.done) {
      Track.watchWebinar(webinar.slug);
    }
    const url = webinar.done ? webinar.event_link : webinar.registration_url;
    window.open(url, '_blank');
  };

  return (
    <StyledContentCard>
      <StyledImageWrapper>
        <img
          src={webinar?.image_url ? webinar?.image_url : '/default_webinar.png'}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/default_webinar.png';
          }}></img>
      </StyledImageWrapper>
      <StyledInfoContainer>
        <div>
          <Title className="underlinable link" to={`/webinars/${webinar.slug}`}>
            {webinar?.title}
          </Title>

          <Date>
            {meeting_date}, via {webinar.tool_type}
          </Date>
          <Chip
            icon={'/clock.svg'}
            text={`${webinar.start_time
              .replace('am', '')
              .replace('pm', '')} - ${webinar.end_time.toUpperCase()}`}
          />
        </div>
      </StyledInfoContainer>
      <ButtonContainer>
        <ButtonWithArrow
          variant="green"
          onClick={openWebinar}
          text={webinar.done ? 'Watch Now' : 'Register'}
        />
      </ButtonContainer>
    </StyledContentCard>
  );
};

export default WebinarCardFullWidth;
