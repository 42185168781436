import React from 'react';
import { Image } from 'react-bootstrap';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  padding: 0px;
  font-weight: 700;
  font-size: 14px;
  background: none;
  width: max-content;
  border: none;
  &.green {
    color: #1db4b3;
  }
  &.green:hover {
    color: #1db4b3;
  }
  &.white {
    color: white;
  }
  &.white:hover {
    color: white;
  }
  &.black {
    color: black;
  }
  &.black:hover {
    color: black;
  }
`;

const ButtonWithArrow = ({ variant = 'white', onClick, text, className }) => {
  return (
    <StyledButton className={`${variant} ${className}`} onClick={onClick}>
      {text} {variant === 'green' && <Image src="chevron-right-green.svg" />}
      {variant === 'white' && <Image src="chevron-right.svg" />}
      {variant === 'black' && <Image src="chevron-right-black.svg" />}
    </StyledButton>
  );
};

export default ButtonWithArrow;
