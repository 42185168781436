import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';

const StyledToolTip = styled(Tooltip)`
  .arrow::before {
    display: none;
  }
  .tooltip-inner {
    font-size: 12px;
    background-color: white;
    border-radius: ${({ theme }) => theme.radius.default};
    color: black;
    max-width: 350px;
    padding: ${({ theme }) => theme.spacing.xs};
    white-space: pre-line;
  }
`;

const StyledInfo = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textBodyLight};
`;

const TimeSlotHeader = ({ type = 'Preference', selectedSession }) => {
  const [preferences, setPreferences] = useState([]);
  const fetchPreferences = async () => {
    const res = await fetch('/api/v2/preferences');
    if (res.status == 200) {
      const data = await res.json();
      setPreferences(data);
    }
  };

  useEffect(() => {
    fetchPreferences();
  }, []);

  const renderTooltip = (props) => (
    <StyledToolTip id="button-tooltip" {...props}>
      <div>
        Your Preferences:
        <br />
        Gender: {preferences?.gender}
        <br />
        Language: {preferences?.language}
      </div>
    </StyledToolTip>
  );

  return (
    <>
      {type === 'Preference' && (
        <>
          <strong className="mr-1">
            {selectedSession == 'counseling' ? 'Counselor' : 'Coaches'} Based on
            your Preferences
          </strong>
          <OverlayTrigger overlay={renderTooltip}>
            <Image src="info-circle.svg" />
          </OverlayTrigger>
        </>
      )}
      {type === 'Other' && (
        <>
          {' '}
          <strong>
            Any available{' '}
            {selectedSession == 'counseling' ? 'Counselor' : 'Coaches'}
          </strong>
          <br />
          <StyledInfo>These may not fully match your preferences</StyledInfo>
        </>
      )}
    </>
  );
};

export default TimeSlotHeader;
