import React from 'react';
import styled from '@emotion/styled';
import { Image } from 'react-bootstrap';

const ProfessionalImage = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 40px;
`;

const ProfessionalImageText = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: white;
  background: ${({ theme }) => theme.colors.brandPrimaryDefault};
  width: 40px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  border-radius: 40px;
`;

const getProfessionalInitials = (professional) => {
  return professional
    .split(' ')
    .map((word) => word[0])
    .join('');
};

const ProfessionalImageComponent = ({ meeting }) => {
  const initials = meeting.professional
    ? getProfessionalInitials(meeting.professional)
    : '';

  return (
    <>
      {meeting?.professional_photo_url ? (
        <ProfessionalImage src={meeting.professional_photo_url} />
      ) : (
        <ProfessionalImageText>{initials}</ProfessionalImageText>
      )}
    </>
  );
};
export default ProfessionalImageComponent;
