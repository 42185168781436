import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Layout from '../Layout';
import Loader from '../Content/Card/Loader';
import StyledSubmitButton from '../shared/StyledSubmitButton';

const LearningModule = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [module, setModule] = useState({});
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    findModule(id);

    // Ensure we are on the top of the page
    window.scrollTo(0, 0);
  }, []);

  const findModule = async (id) => {
    const res = await fetch(`/api/v2/learning_modules/${id}`);
    if (res.status === 200) {
      const object = await res.json();
      setModule(object);

      const { status } = object;
      if (status === "started") {
        setStarted(true);
      } else if (status === "completed") {
        setCompleted(true);
      };
    };
    setLoading(false);
  };

  const startModule = async (id) => {
    const res = await fetch(`/api/v2/user_learning_modules/${id}/start`, {
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').content
      }
    });

    if (res.status === 200) {
      setStarted(true);
    };
  }

  const completeModule = async (id) => {
    const res = await fetch(`/api/v2/user_learning_modules/${id}/complete`, {
      method: "PATCH",
      headers: {
        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').content
      }
    });
    if (res.status === 200) {
      setCompleted(true);
    };
  }

  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  };

  return (
    <Layout>
      <Row className="justify-content-center">
        <article className="col-md-8 d-flex flex-column justify-content-center">
          <header className="bg-green rounded p-4 mb-4 d-flex flex-column justify-content-center">
            <img src={module.image_url ? module.image_url : "/default_article.png"}
              alt={`Learning Module: ${module.title}'s display image`}
              className="img-fluid mx-auto mb-4"
              style={{maxHeight: "400px", width: "fit-content"}}
            />
            <h1 className="p-0 m-0 text-center text-white font-weight-bolder">{module?.title}</h1>
          </header>
          <h2>Summary</h2>
          <p>{module?.summary}</p>
          {module?.objectives?.length > 0 &&
            <>
              <h2>Objectives</h2>
              <ul>
                {module.objectives.map((obj, index) => (
                  <li key={`${obj}-${index}`}>{obj}</li>
                ))}
              </ul>
            </>
          }

          { (started || completed) && <div dangerouslySetInnerHTML={{__html: module.html_content }} /> }

          { (started && !completed) &&
            <div className="d-flex flex-column">
              <StyledSubmitButton onClick={() => completeModule(id)} className="mx-auto mb-2" style={{maxWidth: "fit-content"}}>
                Complete this Module
              </StyledSubmitButton>
              <button
                onClick={() => history.back()}
                className="mx-auto btn btn-white text-muted"
                style={{fontSize: "0.8rem"}}>
                Quit Learning
              </button>
            </div>
          }

          { (!started && !completed) &&
            <div className="d-flex">
              <StyledSubmitButton onClick={() => startModule(id)} className="mx-auto" style={{maxWidth: "fit-content"}}>
                Start this Module
              </StyledSubmitButton>
            </div>
          }

          { completed &&
            <div className="text-center mt-4">
              <h3 className="text-center">Successfully completed the Learning Module!</h3>
              <StyledSubmitButton className="mx-auto" style={{maxWidth: "fit-content"}}>
                <Link to={{pathname: "/learning_modules"}}
                  className="text-green text-decoration-none">
                  Check out other Learning Modules
                </Link>
              </StyledSubmitButton>
            </div>
          }
        </article>
      </Row>
    </Layout>
  );
};

export default LearningModule;