import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Loader from '../Content/Array/Loader';
import LearningModule from './Card';
import StyledHeader from '../shared/StyledHeader';
import StyledHeading from '../shared/StyledHeading';

const LEARNING_MODULES_PATH = '/api/v2/learning_modules';
const MY_LEARNING_MODULES_PATH = '/api/v2/user_learning_modules';

const DashboardSection = ({ url, heading, description, indexPath }) => {
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    fetchModules();
  }, []);

  async function fetchModules() {
    const res = await fetch(url);
    const learningModules = await res.json();

    setLoading(false);
    if (learningModules.length > 0) {
      setModules(learningModules);
    }
  }

  if (loading) {
    return <Loader />;
  }

  if (modules.length < 1) {
    return <></>;
  }

  return (
    <div className="mb-5">
      <Row>
        <StyledHeader>
          <StyledHeading>{heading}</StyledHeading>
          <Link to={{ pathname: indexPath }} className="text-green">
            View all
          </Link>
        </StyledHeader>
      </Row>
      <Row className='mb-4'>
        <Col md={10} style={{ fontSize: '14px' }}>
          {description}
        </Col>
      </Row>
      <Row>
        {modules.map((m) => (
          <LearningModule key={m.id} module={m} />
        ))}
      </Row>
    </div>
  );
};

export default DashboardSection;
export { LEARNING_MODULES_PATH, MY_LEARNING_MODULES_PATH };
