import React from 'react';

const AnyTimeSlot = () => (
  <SelectedSlotContainer>
    <UIButton text="08:00 am" className="white bordered" onClick={() => {}} />
    <UIButton text="In-person" className="white bordered" onClick={() => {}} />
    <UIButton text="Any" className="white bordered" onClick={() => {}} />
  </SelectedSlotContainer>
);

export default AnyTimeSlot;
