import React from 'react';
import { StyledContainer, LeftContent, RightContent } from './cardstyles';
import styled from '@emotion/styled';
import ButtonWithArrow from '../../shared/ui/ButtonWithArrow';

const StyledDashboardCard = styled(StyledContainer)`
  background: #eaeaff;
`;

const CheckInDashboard = ({ setShowCheckInModal }) => {
  return (
    <StyledDashboardCard>
      <LeftContent>
        <h6>What’s your mood today?</h6>
        <p>Pay attention to your feelings.</p>
      </LeftContent>
      <RightContent>
        <ButtonWithArrow
          variant="green"
          onClick={setShowCheckInModal}
          text="Track your Mood"
        />
      </RightContent>
    </StyledDashboardCard>
  );
};

export default CheckInDashboard;
