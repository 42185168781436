import styled from '@emotion/styled';
import { UIButton } from '../../shared/ui';

export const StyledHeader = styled.header`
  margin-bottom: 0px;
  text-align: center;
  .book-session, &.display-header {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.textBodyLight};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
  }
  .sub-title{
    font-size: 14px;
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
`;

export const Selection = styled.div`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  padding: 10px 20px;
  display: block;
  font-size: 0.8rem;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px auto 17px auto;
  max-width: 450px;
  cursor: pointer;
  &.active {
    color: #1db4b3;
    border: #1db4b3 2px solid;
    background-color: ${({ theme }) => theme.colors.surfaceSubtleEmphasis};
  }
  &.even {
    margin-right: 12px;
  }
  &.odd {
    margin-left: 12px;
  }
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    &.even,
    &.odd {
      margin: auto;
      margin-bottom: 20px;
    }
  }
`;

export const StyledUIButton = styled(UIButton)`
  width: fit-content;
  display: block;
  text-align: center;
  margin: auto;
  padding: 11px 51px;
`;
