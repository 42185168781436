import React, { createContext, useContext, useState } from 'react';
import { useAppData } from './AppDataProvider';
import { type } from 'jquery';

export const BookSessionContext = createContext();
export function useBookSessionData() {
  return useContext(BookSessionContext);
}
const BookSessionDataProvider = ({ currentStep, setCurrentStep, children }) => {
  const [outOfSessionOption, setOutOfSessionOption] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [previousStep, setPreviousStep] = useState(currentStep);
  const [selectedDateSlotType, setSelectedDateSlotType] = useState({
    date: null,
    slot: null,
    type: null
  });
  const [selectedMeetingSetup, setSelectedMeetingSetup] = useState(null);
  const [reviewDetails, setReviewDetails] = useState({
    notes: null,
    contact_number: null,
    professional: null,
    booking_details: null
  });

  const resetFields = () => {
    setReviewDetails({
      notes: null,
      contact_number: null,
      professional: null,
      booking_details: null,
    });
    setSelectedDateSlotType({
      date: null,
      slot: null,
      type: null
    });
    setSelectedReasons([]);
    setOutOfSessionOption(null)
    setSelectedSession(null);
    setSelectedMeetingSetup(null);
  }
  
  const value = {
    currentStep,
    setCurrentStep,
    selectedSession,
    setSelectedSession,
    selectedReasons,
    setSelectedReasons,
    selectedDateSlotType,
    setSelectedDateSlotType,
    reviewDetails,
    setReviewDetails,
    outOfSessionOption,
    setOutOfSessionOption,
    resetFields,
    previousStep,
    setPreviousStep,
    selectedMeetingSetup,
    setSelectedMeetingSetup,
  };
  return (
    <BookSessionContext.Provider value={value}>
      {children}
    </BookSessionContext.Provider>
  );
};
export default BookSessionDataProvider;