import React from 'react';
import { format } from 'date-fns';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer
      className="text-center bg-light py-4 pb-2 mt-4"
      style={{ fontSize: '0.7rem' }}>
      <Container>
        <Row className="d-flex mb-2">
          <img className="mx-auto img-fluid" src="/logo.svg" />
        </Row>
        <Row>
          <Col className="text-center">
            <p>
              If you are in crisis, please call these numbers for immediate
              help:
              <br />
              National Center for Mental Health (NCMH) Crisis Hotlines – 0917
              899 8727 (USAP) and 989 8727 (USAP)
            </p>
            <p className="my-4">
              <a
                role="button"
                href="https://infinitcare.co/#about-us"
                className="font-weight-bold text-dark mr-4"
                target="_blank">
                About Infinit Care
              </a>
              <a
                href="https://infinitcare.co/individuals/"
                className="font-weight-bold text-dark mr-4"
                target="_blank">
                How Consulting Works
              </a>
              <a
                href="https://infinitcare.co#content"
                className="font-weight-bold text-dark mr-4"
                target="_blank">
                Blog
              </a>
              <a
                href="mailto:info@infinitcare.co"
                className="font-weight-bold text-dark mr-4">
                Contact Us
              </a>
            </p>
            <p className="my-4">
              <span className="text-dark mr-4">
                Copyright &copy; {format(new Date(), 'YYY')} Infinit Care
              </span>
              <a href="/privacy-policy" className="text-dark mr-4">
                Privacy Policy
              </a>
              <a href="/terms" className="text-dark mr-4">
                Terms &amp; Conditions
              </a>
              <a href="/bir" target="_blank" className="text-dark mr-4">
                BIR Certification
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
