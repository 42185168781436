import React, { useState, useEffect } from 'react';
import 'react-day-picker/dist/style.css';
import Layout from '../Layout';
import SelectSession from './Coaching/SelectSession';
import SelectReasons from './Coaching/SelectReasons';
import CalendarSessions from './Coaching/CalendarSessions';
import ReviewSession from './Coaching/ReviewSession';
import Preferences from './Coaching/Preferences';
import BookingDone from './Coaching/BookingDone';
import ConsentForm from './Coaching/ConsentForm';
import BookSessionDataProvider from '../contexts/BookSessionDataProvider';
import OutOfSession from './OutOfSession';
import OutOfSessionReason from './OutOfSessionReason';
import OutOfSessionSubmitted from './OutOfSessionSubmitted';
import SalaryDeductionForm from './SalaryDeductionForm';

const BookSession = () => {
  const [currentStep, setCurrentStep] = useState('select_session');
  const [reasons, setReasons] = useState([]);
  const [availableMeetingService, setAvailableMeetingService] = useState({
    counseling_available: false,
    coaching_available: false,
  });
  const fetchReasons = async () => {
    const res = await fetch('/api/v2/coaching_reasons');
    if (res.status == 200) {
      const reasons = await res.json();
      setReasons(reasons);
    }
  };

  const fetchAvailableMeetingsServices = async () => {
    const res = await fetch('/api/v2/meeting_services_availabilities');
    if (res.status == 200) {
      const availabilites = await res.json();
      setAvailableMeetingService(availabilites);
    }
  };

  useEffect(() => {
    fetchReasons();
    fetchAvailableMeetingsServices();
  }, []);

  const stepsInPercent = {
    select_session: 25,
    out_of_session: 35,
    consent_form: 35,
    select_reasons: 50,
    preference_form: 65,
    out_of_session_reason: 65,
    salary_deduction_form: 65,
    select_date_time: 75,
    review_session: 100,
    out_of_session_submitted: 100
  };

  return (
    <BookSessionDataProvider
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      >
      {currentStep != 'booking_done' && (
        <Layout
          progressBar={true}
          progressBarValue={stepsInPercent[currentStep]}>
          {currentStep == 'select_session' && (
            <SelectSession availableMeetingService={availableMeetingService} />
          )}
          {currentStep == 'preference_form' && <Preferences />}
          {currentStep == 'consent_form' && <ConsentForm />}
          {currentStep == 'select_reasons' && (
            <SelectReasons reasons={reasons} />
          )}
          {currentStep == 'coaching' && (
            <SelectReasons reasons={reasons} />
          )}
          {currentStep == 'select_date_time' && <CalendarSessions />}
          {currentStep == 'review_session' && <ReviewSession />}
          {/* Out of Session Screens */}
          {currentStep == 'out_of_session' && <OutOfSession />}
          {currentStep == 'out_of_session_reason' && <OutOfSessionReason />}
          {currentStep == 'out_of_session_submitted' && (
            <OutOfSessionSubmitted />
          )}
          {currentStep == 'salary_deduction_form' && <SalaryDeductionForm />}
        </Layout>
      )}
      {currentStep == 'booking_done' && (
        <Layout>
          <BookingDone />
        </Layout>
      )}
    </BookSessionDataProvider>
  );
};
export default BookSession;

