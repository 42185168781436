import React, { useState } from 'react';
import ProfileNavigation from '../../shared/ProfileNavigation';
import Display from './Display';
import axios from 'axios';
import useSWR from 'swr';
import AddForm from './AddForm';

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Dependents = () => {
  const [addDependent, setAddDependent] = useState(false);
  const { data: dependents } = useSWR(`/api/v2/user_dependents`, fetcher);

  return (
    <div className="container personal-information">
      <div className="row mt-5">
        <ProfileNavigation />
        <div className="col col-lg-9 col-md-8 col-sm-12 mt-2">
          {!addDependent && (
            <button
              type="button"
              onClick={() => setAddDependent(true)}
              style={{ height: 'fit-content' }}
              className="mt-2 mb-2 btn btn-general bg-white text-dark border rounded mr-2">
              Add Dependent
            </button>
          )}
          {addDependent && (
            <div className="card mt-2 mb-2">
              <div className="card-body">
                <AddForm setEditing={setAddDependent} />
              </div>
            </div>
          )}
          {!!dependents && dependents.length > 0 && (
            <div className="card">
              <div className="card-body" style={{ overflow: 'auto' }}>
                <table className="table">
                  <thead>
                    <tr style={{ fontSize: '0.85rem' }}>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Relationship</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th># of sessions consumed</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dependents.map((dependent) => (
                      <Display key={dependent.id} user={dependent} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Dependents;
