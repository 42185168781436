import useSWR from 'swr';
import axios from 'axios';
import {
  StyledCol,
  SeeAllLink,
} from './styles';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import Layout from '../Layout';
import Sessions from './Sessions';
import CheckInModal from './CheckInModal';

import DashboardWebinarArray from '../Webinars/Array/DashboardWebinarArray';
import Quote from '../shared/Quote';
import CheckInDashboard from './components/CheckInDashboard';
import ContentCardFullWidth from '../Content/Card/ContentCardFullWidth';
import OnBoardingModal from '../shared/modals/OnBoardingModal';
import Loader from './Loader';
import PodcastDashboard from './PodcastDashboard';
import SessionsCard from './components/SessionsCard';
import ViewPodcastsCard from './components/ViewPodcastsCard';
import UpcomingSessionCard from './components/UpcomingSessionCard';
import StyledHeading from '../shared/StyledHeading';
import StyledHeader from '../shared/StyledHeader';

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Dashboard = () => {
  const [showCheckInModal, setShowCheckInModal] = useState(false);

  const { data: dashboard } = useSWR('/api/v2/dashboard', fetcher, {
    revalidateOnFocus: false,
  });

  const [
    { done_onboarding, should_check_in, events, nudges, events_type, sessions_count_visible, nearest_meeting },
    setDashboard,
  ] = useState({
    done_onboarding: true,
    should_check_in: false,
    events: null,
    nudges: null,
    assessment: null,
    meeting: null,
    events_type: null,
    sessions_count_visible: false,
    nearest_meeting: null,
  });

  useEffect(() => {
    if (!!dashboard) {
      setDashboard(dashboard);
    }
  }, [dashboard]);

  const hideCheckInDashboard = () => {
    setDashboard((currentState) => ({
      ...currentState,
      should_check_in: false,
    }));
  };

  return (
    <Layout>
      <Row>
        <Col lg={3}>
          <Sessions />
        </Col>
        <StyledCol lg={9} sm={12}>
          {(sessions_count_visible || !nearest_meeting) && (
  <StyledHeading className="mb-4">Sessions</StyledHeading>
)}

          <UpcomingSessionCard />
          <SessionsCard />
          {should_check_in && (
            <>
              <StyledHeading className="mt-5">For Today</StyledHeading>
              <CheckInDashboard setShowCheckInModal={setShowCheckInModal} />
              <ViewPodcastsCard />
            </>
          )}
          {nudges?.show_podcast_banner && <PodcastDashboard />}
          {!nudges && <Loader />}
          {nudges && (
            <div className="mb-3 mt-5">
              <Row>
                <StyledHeader>
                  <StyledHeading>For You</StyledHeading>
                </StyledHeader>
                {nudges?.contents?.map((content, index) => (
                  <ContentCardFullWidth
                    key={index}
                    withContent={true}
                    data={content}
                  />
                ))}
                {nudges?.webinars && (
                  <div className="mb-3 w-100">
                    <DashboardWebinarArray data={nudges?.webinars} />
                  </div>
                )}
              </Row>
            </div>
          )}
          {events?.length > 0 && (
            <div className="mb-3">
              <Row>
                <StyledHeader>
                  <StyledHeading>Latest Events</StyledHeading>
                  <SeeAllLink
                    to={{
                      pathname: `/webinars`,
                      search: `?events_type=${events_type}`,
                    }}>
                    See All
                  </SeeAllLink>
                </StyledHeader>
              </Row>
              <Row>
                <DashboardWebinarArray data={events} />
              </Row>
            </div>
          )}

          {nudges?.quote && (
            <Row>
              <Col>
                <Quote quote={nudges?.quote} />
              </Col>
            </Row>
          )}
        </StyledCol>
      </Row>
      {showCheckInModal && (
        <CheckInModal
          setShowCheckInModal={setShowCheckInModal}
          hideCheckInDashboard={hideCheckInDashboard}
        />
      )}
      {!done_onboarding && <OnBoardingModal />}
    </Layout>
  );
};

export default Dashboard;
