import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Arrangements from './Arrangements';
import { Image } from 'react-bootstrap';

const CounselorsContainer = styled.div`
  padding: 30px 30px;
  display: flex;
  flex: 1 1 0;
  width: 0;
  flex-direction: column;
  min-width: 400px;
  background: ${({ theme }) => theme.colors.surfaceContrast};
`;

const Counselor = styled.button`
  display: flex;
  gap: 16px;
  padding: 8px 12px;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.radius.default};
  align-items: center;
  border: none;
  .counselor-name {
    margin: 0;
    text-align: left;
  }
  &.active {
    font-weight: 700;
    background: ${({ theme }) => theme.colors.surfaceSubtleEmphasis};
    .counselor-name {
      color: ${({ theme }) => theme.colors.brandPrimaryDefault};
    }
  }
`;
const Avatar = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.radius.radiusRound};
`;

const Counselors = ({
  meetingSetup,
  previousProfessionals,
  selectedCounselor,
  setSelectedCounselor,
  selectedArrangement,
  setSelectedArrangement,
  availablePrevProfessionals,
}) => {
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    setProfessionals(mapProfessionals());
  }, [previousProfessionals, availablePrevProfessionals]);

  useEffect(() => {
    const availableProfessionals = professionals.filter((counselor) => !counselor.disabled)
    if (availableProfessionals.length > 0 && !selectedCounselor) {
      setSelectedCounselor(availableProfessionals[0]);
    }
  }, [professionals]);

  const meetingSetups = (professionalId) => {
    return (
      availablePrevProfessionals.find(
        (professional) => professional.professional_id === professionalId,
      )?.meeting_setups || []
    );
  };

  const counselorDisabled = (meetingSetups) => {
    if (
      meetingSetups.toString() == ['on_site'].toString() &&
      selectedArrangement === 'virtual'
    ) {
      return {
        disabled: true,
        message: 'Available only for In-Person sessions',
      };
    } else if (
      meetingSetups.toString() == ['virtual'].toString() &&
      selectedArrangement === 'on_site'
    ) {
      return { disabled: true, message: 'Available only for Virtual sessions' };
    } else if (meetingSetups.length == 0) {
      return { disabled: true, message: 'Not Available' };
    } else {
      return { disabled: false, message: '' };
    }
  };

  const mapProfessionals = () => {
    const newCounselors = previousProfessionals.map((counselor) => {
      counselor.meetingSetup = meetingSetups(counselor.id);
      const isDisabled = counselorDisabled(counselor.meetingSetup);
      counselor.disabled = isDisabled.disabled;
      counselor.message = isDisabled.message;
      return counselor;
    });
    return newCounselors;
  };

  return (
    <CounselorsContainer>
      {meetingSetup === 'hybrid' && (
        <Arrangements
          selectedArrangement={selectedArrangement}
          setSelectedArrangement={setSelectedArrangement}
        />
      )}
      {professionals.map((counselor) => (
        <>
          {counselor.disabled && (
            <Counselor key={counselor} disabled>
              <Avatar src={counselor?.avatar_url} />
              <p className="counselor-name">
                {counselor.full_name} ({counselor.message})
              </p>
            </Counselor>
          )}

          {!counselor.disabled && (
            <Counselor
              key={counselor}
              className={`${
                selectedCounselor?.id === counselor?.id ? 'active' : ''
              }`}
              onClick={() => setSelectedCounselor(counselor)}>
              <Avatar src={counselor?.avatar_url} />
              <p className="counselor-name">{counselor.full_name}</p>
            </Counselor>
          )}
        </>
      ))}
      <Counselor
        className={`${!selectedCounselor?.id ? 'active' : ''}`}
        onClick={() => setSelectedCounselor(null)}>
        <Avatar src="./happy-smile.svg" />
        <p className="counselor-name">Any Counselor</p>
      </Counselor>
    </CounselorsContainer>
  );
};

export default Counselors;
