import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';

const StyledHeader = styled(Col)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px) {
    display: flex;
    justify-content: space-between;
  }
`;

export default StyledHeader;