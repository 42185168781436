import React from 'react';
import mixpanel from 'mixpanel-browser';
import { BrowserRouter as Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import './shared/ErrorHandler';
import AppDataProvider from './contexts/AppDataProvider';
import ActiveRoutes from './Routes/ActiveRoutes';
import DisabledEmailRoutes from './Routes/DisabledEmailRoutes';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';

const Main = (appData) => {
  const { mixpanelToken, sessionsLeft, companyID, emailValidity, userId } =
    appData;
  mixpanel.init(mixpanelToken, {
    track_pageview: true,
  });
  mixpanel.identify(userId);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: true,
      }}>
      <ThemeProvider theme={theme}>
        <AppDataProvider {...appData}>
          <Router basename="/">
            {emailValidity == 'update_required' ? (
              <DisabledEmailRoutes />
            ) : (
              <ActiveRoutes sessionsLeft={sessionsLeft} companyID={companyID} />
            )}
          </Router>
        </AppDataProvider>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default Main;
