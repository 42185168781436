import styled from '@emotion/styled';
import React, { useState } from 'react';
import { StyledButton } from '../../shared/ui/Button';
import AssessmentWelcomeModal from '../../Assessment/WelcomeModal';
import { useAppData } from '../../contexts/AppDataProvider';
import { Image } from 'react-bootstrap';

const StyledContainer = styled.div`
  background: #164a4b;
  border-radius: 8px;
  .description {
    color: white;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  button {
    font-size: 14px;
  }
  @media (max-width: 767.98px) {
    img {
      flex: 1;
      max-width: 40% !important;
    }
  }
`;
const DescriptionContainer = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 0px;
  @media (max-width: 991px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`;
const ButtonContainer = styled.div`
  padding: 24px 16px;
`;

const TakeAssessmentCard = () => {
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const { setCurrentAssessment } = useAppData();
  const toggleAssessmentModal = () => {
    setShowAssessmentModal(!showAssessmentModal);
  };

  return (
    <>
      {showAssessmentModal && (
        <AssessmentWelcomeModal setCurrentAssessment={setCurrentAssessment} />
      )}
      <StyledContainer>
        <DescriptionContainer>
          <p className="description">Take your wellbeing assessment!</p>
          <Image src="/assessment/pitcher_plant.svg" />
        </DescriptionContainer>
        <ButtonContainer>
          <StyledButton className="white" onClick={toggleAssessmentModal}>
            Start Assessment
          </StyledButton>
        </ButtonContainer>
      </StyledContainer>
    </>
  );
};

export default TakeAssessmentCard;
