import React from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { StyledContainer, LeftContent } from './cardstyles';
import MeetingCancel from './../../Meetings/Cancel';
import styled from '@emotion/styled';
import Chip from '../../shared/ui/Chip';

const StyledDashboardCard = styled(StyledContainer)`
  padding: 0px;
  background: linear-gradient(90deg, #146e71 0%, #0ec5c5 100%);
  gap: 20px;
`;

const StyledLeftContent = styled(LeftContent)`
  width: 50%;
  padding: 27px 0px 27px 24px;
  color: white;
  .description {
    font-weight: 700;
  }
  .emphasize {
    font-weight: 900;
  }
`;
const RightContent = styled.div`
  padding: 27px 24px 27px 0px;
  &.not-today {
    padding: 35px 24px 35px 0px;
  }
  background: url('/session-illustration-green.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: end;
  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

const StyledButton = styled.button`
  color: #333333;
  background: #f5f5f5;
  padding: 5px 24px 5px 24px;
  border: none;
  border-radius: 8px;
  width: 240px;
  height: 46px;
  margin-bottom: 16px;
  color: #1db4b3;
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
  &:hover,
  &:focus {
    color: #1db4b3;
  }
  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

const ChipContainer = styled.div`
  margin-bottom: 16px;
`;

const DATE_OPTIONS = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

const fetcher = (url) => axios.get(url).then((res) => res.data);

const UpcomingSessionCard = () => {
  const { data } = useSWR('/api/v2/meetings', fetcher);
  let nearest_meeting = {};
  if (data) {
    nearest_meeting = data.nearest_meeting;
  }
  if (!nearest_meeting?.date) {
    return null;
  }

  return (
    <StyledDashboardCard>
      <StyledLeftContent>
        {data?.meeting_today && nearest_meeting?.date && (
          <p className="description">
            You have{' '}
            {`${nearest_meeting?.setup === 'on_site' ? 'an onsite' : 'a'}`}{' '}
            counseling session today at <br />
            <strong className="emphasize">{nearest_meeting?.start_time}</strong>
            .
          </p>
        )}
        {nearest_meeting?.date && !data?.meeting_today && (
          <p className="description">
            You have an upcoming{' '}
            {`${nearest_meeting?.setup === 'on_site' ? 'on site' : ''}`}{' '}
            {nearest_meeting.purpose} session this{' '}
            <strong className="emphasize">
              {new Date(nearest_meeting?.date).toLocaleDateString(
                'en-US',
                DATE_OPTIONS,
              )}
            </strong>{' '}
            at <strong>{nearest_meeting?.start_time}</strong>.
          </p>
        )}
      </StyledLeftContent>
      <RightContent className={`${!data?.meeting_today ? 'not-today' : ''}`}>
        {data?.meeting_today && (
          <>
            {data?.meeting_url &&
              data?.meeting_url !== '' &&
              nearest_meeting?.setup !== 'on_site' && (
                <StyledButton href={data?.meeting_url} target="_blank">
                  Join session
                </StyledButton>
              )}
          </>
        )}
        {nearest_meeting?.date && !data?.meeting_today && (
          <ChipContainer>
            <Chip
              variant="dark-green"
              icon={'/white-calendar.svg'}
              text={`${data?.days_until_next_session} ${
                data?.days_until_next_session === 1 ? 'day' : 'days'
              } remaining`}
            />
          </ChipContainer>
        )}
        {nearest_meeting?.id && (
          <MeetingCancel variant="with_chevron" meeting={nearest_meeting} />
        )}
      </RightContent>
    </StyledDashboardCard>
  );
};

export default UpcomingSessionCard;
